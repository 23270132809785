// Include your assignment 1 below.
// Read the PDF for instruction on what to do.
// Ensure that you look at the "Marking Key" section of the PDF
// to not lose any marks.

// include bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

import { getWeather } from './api/base';
import { renderWeather } from './dom/weather';

const searchClicked = (e) => {
    e.preventDefault();
    const cityInput = document.getElementById('weather-search-input');
    const cityName = cityInput.value.trim();

    if (cityName) {
        getWeather(cityName)
            .then(weatherInfo => renderWeather(weatherInfo, '.weather-container'))
            .catch(error => console.error('Error in renderWeather:', error));
    } else {
        alert('Please enter a valid city name');
    }
};

document.getElementById('weather-search-button').addEventListener('click', searchClicked);

