const API_KEY = "62ac1322ec53c62c861a98010efb5634"

const getWeather = async (cityName) => {
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${API_KEY}`;
    const response = await fetch(url);
    const data = await response.json();
        try {
            // console.log('response.status:', response.status);
            if (response.status === 404) {
                throw new Error('Please enter a valid city name');
            } else {
                // console.log("returning data");
                return data;
            }
        } catch (error) {
            // console.log('Caught an error:', error.message);
            const weatherContainer = document.querySelector('.weather-container');
            setTimeout(() => {
                weatherContainer.innerHTML = `<div class="border border-danger align-middle"><h5 class="text-center text-danger">${error.message}</h5></div>`;
            }, 500); // Delay of 0.5 seconds
        }
}

export { getWeather };