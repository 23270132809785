/*
HTML Structure 


Note: I'm sure you've read the PDF but there will be no marks given
for using innerHTML. 

<div class="mt-2 card" >
  <div class="card-body">
    <h5 class="card-title">CITY_NAME_HERE, COUNTRY_CODE_HERE</h5>
    <h6 class="card-subtitle mb-2 text-muted">CURRENT_WEATHER_DEGREES_HERE</h6>
    <p class="card-text">WEATHER_DESCRIPTION_HERE</p>
  </div>
</div>

*/

// renderWeather function with an argument of weather and the element to append to which has a class of weather-container

const renderWeather = (weather_reply, element) => {
  const weatherSearchInput = document.getElementById('weather-search-input');
  weatherSearchInput.value = '';
  const weatherContainer = document.querySelector(element);
  weatherContainer.classList.add('mt-2', 'card', 'card-body');

  weatherContainer.innerHTML = '';

  const cityNameElement = document.createElement('h5');
  cityNameElement.classList.add('card-title');
  cityNameElement.textContent = weather_reply.name + ', ' + weather_reply.sys.country;

  const temperatureElement = document.createElement('p');
  temperatureElement.classList.add('card-subtitle', 'mb-2', 'text-muted');
  temperatureElement.textContent = `Temperature: ${Math.round(weather_reply.main.temp - 273.15)}°C`;

  const descriptionElement = document.createElement('p');
  descriptionElement.classList.add('card-text');
  descriptionElement.textContent = `Description: ${(weather_reply.weather[0].description).charAt(0).toUpperCase()}${(weather_reply.weather[0].description).slice(1)}`;

  const humidityElement = document.createElement('p');
  humidityElement.textContent = `Humidity: ${weather_reply.main.humidity}%`;

  weatherContainer.appendChild(cityNameElement);
  weatherContainer.appendChild(temperatureElement);
  weatherContainer.appendChild(descriptionElement);
  weatherContainer.appendChild(humidityElement);
};

export { renderWeather };